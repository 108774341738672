<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return '8cf88045-b5f3-4e92-9428-d7fe440ce4c1'
    }
  }
}
</script>
